<template>
  <div>
    <v-app-bar app elevation="0" class="white">
      <v-btn icon @click="$router.push('/welcome/signin')"
        ><v-icon color="primary">mdi-arrow-left</v-icon></v-btn
      >
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card elevation="0">
              <v-card-text>
                <p class="text-h4 primary--text text--darken-4">
                  กรอกรหัสยืนยัน
                </p>
                <div>
                  <span class="text-subtitle-1 natural--text text--darken-2">
                    รหัส OTP ได้ส่งไปยัง sms ที่หมายเลข <br />
                    <span class="text-subtitle-1 secondary--text">
                     {{mobile}} </span
                    ><br />
                    โดยรหัสมีอายุใช้งาน 5 นาที
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="0" class="text-center">
               <v-card :disabled="loading"  elevation="0" class="text-center">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  separator=""
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
                
               </v-card>
              <div v-if="error" class="pt-3 error--text text-title-5">
                {{$t('otp.wrongpass')}}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-btn
              large
              block
              color="primary"
              class="text-subtitle-1"
              :disabled="!requestStatus"
              @click="requestOTP"
              >{{ $t("otp.button") }} </v-btn
            >
          </v-col>
          <v-col cols="12">
            <div v-if="!requestStatus" class="text-center primary--text text-title-2">
              {{ $t("otp.resendotp",{msg: countDown})}}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="network">
        <NetworkCard @onClose="network = false" />
      </v-dialog>
      <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    </v-main>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
//import { mapFields } from 'vuex-map-fields';
import NetworkCard from "@/components/common/NetworkCard";
import { offlineimage } from "@/mixins/offlineimage";
import { login,otp } from "@/api/public";

export default {
  components: {
    "v-otp-input": OtpInput,
    NetworkCard,
  },
  props: {
      mobile: {
          type: String
      },
  },
  mixins: [offlineimage],
  data() {
    return {
      network: false,
      loading: false,
      sms: this.mobile,
      requestStatus: false,
      countDown: 30,
      error: false
    };
  },
  methods: {
    handleOnComplete(value) {
      if (this.isOnline) {
           this.loading = true
        login(
          {
            username: this.mobile.replace(/^(\+)|\D/g, "$1"),
            password: value,
          },
          (message) => {
            if (message.data.code === 1) {
              let claims = this.parseJwt(message.data.result.access_token)
              let role =   JSON.parse(claims.role[0])
              role.service = role.service.filter(val => val.service === 'mobileScreeningService')
              this.$offlineStorage.set("token",message.data.result);
              this.$offlineStorage.set("claims",claims);
              this.$offlineStorage.set("role",role);
              console.log(role)
              if(role.consent.termCondition && role.consent.privacyPolicy){
              if(role.consent.termCondition.isAccept && role.consent.privacyPolicy.isAccept){
                 this.$offlineStorage.set("isAuthen", true);
                 this.validatesite()
              }else if(!role.consent.termCondition.isAccept){
                 this.$router.replace({name: 'tou', params: { mode: 'consent'}});
              }else if(!role.consent.privacyPolicy.isAccept){
                 this.$router.replace({name: 'pn', params: { mode: 'consent'}});
              }
              }else{
                 this.$router.replace({name: 'tou', params: { mode: 'consent'}});
              }
              
              //this.$router.replace("/main/home");
            }else{
              this.$refs.otpInput.clearInput()
              this.error=false
            }
             this.loading = false
          },
          (error) => {
            this.loading = false
            this.$refs.otpInput.clearInput()
            this.error=true
            console.log(error);
          }
        );
      } else {
        this.network = true;
      }
    },
    handleOnChange() {
     // console.log("OTP changed: ", value);
    },
    validatesite () {
            let role = this.$offlineStorage.get("role");
            if (role.service[0].tenant.length > 1 ) {
                this.$router.replace({name: 'selecttenant'});
              } else {
                 this.$offlineStorage.set("tenant",role.service[0].tenant[0]);
                 if (role.service[0].tenant[0].site.length > 1) {
                   this.$router.replace({name: 'selectsite'});
                 } else {
                   this.$offlineStorage.set("site",role.service[0].tenant[0].site[0]);
                   this.$router.replace({name: 'home'});
                 }
              }
        },
    requestOTP(){
      if(this.requestStatus){
        this.requestStatus = false
      otp(
          { data: { mobile: this.sms.replace(/^(\+)|\D/g, "$1") } },
          (message) => {
            if (message.data.code === 1) {
             this.loading = false
             this.countDown= 30
             this.countDownTimer()
              //this.$router.replace({ path: "otp/"+this.mobile });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }else{
                    this.requestStatus = true
                }
    },
    parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
    }
  },
  created () {
     this.countDownTimer();
  },
};
</script>

<style lang="scss"  scoped>
.v-btn {
  border-radius: 6px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(128, 160, 233, 0.3) !important;
    color: rgb(255, 255, 255) !important;
}


</style>